import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import WallView from "../WallView";
import "./RoomImageReordering.scss";
import DamageImageOrdering from "./DamageImageOrdering";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import mixpanel from "mixpanel-browser";
import * as events from '../../utils/logger/events';
import portalApi from "../../utils/portalApi";
import {getTask, RoomImageIssue, roomIssue, selectedTaskData} from "../../store/actions/tasks";
import * as Sentry from "@sentry/react";
import tasksApi from "../../utils/tasksApi";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import DoneIcon from '@mui/icons-material/Done';
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';
import {useReadLocalStorage} from 'usehooks-ts';
import {logGlobalCompletionTime, clearCachedItems} from  '../../utils/utils';
import Alert from '@mui/material/Alert';
import {useFlags} from "flagsmith/react";

const RoomImageReordering = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const GETMYDATA = useSelector(
    (state) => state?.taskReducer?.myData?.tasks
  )?.[0] || [];
  const selectedTaskValues = useSelector(
    (state) => state?.taskReducer?.SelectedTaskData
  );
  const TASKDATA = useSelector((state) => state?.taskReducer?.taskData);
  const [damageInfo, setDamageInfo] = useState({})
  const [archivedOverviewImgs, setArchivedOverviewImgs] = useState([]);
  const [changedImageOrder, setChangedImageOrder] = useState([]);
  const [defaultImageOrder, setDefaultImageOrder] = useState([]);
  const { select_16_best_imgs } = useFlags(['select_16_best_imgs']);
  
  // tooltip
  const [showCompleteError, setShowCompleteError] = useState(false);
  const completeBtnRef = useRef(null);
  const startTime = useReadLocalStorage('start-time');
  
  useEffect(() => {
    const tasks = (TASKDATA?.tasks?.length || 0);
    if (tasks > 1 || tasks === 0) {
      alert("Please claim a task to proceed.");
      history.push("/app/qadashboard");
    }
  }, [TASKDATA.tasks, history]);
  
  useEffect(() => {
    if (GETMYDATA?.roomId && GETMYDATA?.claimId) {
      mixpanel.qa.track(events.ROOM_IMAGE_REORDERING_PAGE_LOAD, {
        roomId: GETMYDATA?.roomId,
        claimId: GETMYDATA?.claimId
      });
      
      (async () => {
        try {
          const { data } = await portalApi.get(`claim/${GETMYDATA?.claimId}/room/${GETMYDATA?.roomId}`);
          dispatch(selectedTaskData(data));
        } catch (e) {
          Sentry.captureException(e);
        }
      })();
    }
    // eslint-disable-next-line
  }, [GETMYDATA]);

  useEffect(() => {
    const images = select_16_best_imgs.enabled ? selectedTaskValues.overviewImages : selectedTaskValues.imagesToProcess;
    setDefaultImageOrder(images);
  }, [selectedTaskValues, select_16_best_imgs.enabled]);

  const statusCall = async (status) => {
    const {data} = await tasksApi.post(`tasks/${GETMYDATA?.id}?status=${status}`, {});
    if (data.status) {
      dispatch(roomIssue([]));
      history.push("/app/qadashboard");
    } else {
      alert("facing Some Issue / Please try again later");
    }
  };

  const ImageOrderApiCall = async () => {
    try {
      let imageOrder = [];
      if (changedImageOrder.length > 0) {
        imageOrder = changedImageOrder?.map((img) => {
          return img.imageId; //to be replaced with imageId
        });
      } else {
        imageOrder = defaultImageOrder?.map((img, index) => {
          return img; //to be replaced with img
        });
      }
      
      imageOrder = imageOrder.filter(e => Boolean(e));
      
      const data = {
        image_order: Array.from(new Set(imageOrder)),
        roomId: GETMYDATA?.roomId,
        claimId: GETMYDATA?.claimId,
        archived_images: Array.from(
          new Set(defaultImageOrder.filter(e => !changedImageOrder.map(e => e.imageId).includes(e)))
        ),
        damages_overviewImages:Object.keys(damageInfo).reduce((acc, curr) => {
            if (!damageInfo[curr].archived && !damageInfo[curr].notInScope) {
              acc[curr] = damageInfo[curr].overviewImages.filter((img) => !archivedOverviewImgs.includes(img))
            }
            return acc
        }, {}),
        archived_damages: Array.from(
          new Set(Object.keys(damageInfo).filter(x => Boolean(damageInfo[x]?.archived)).map(e => e))
        ),
        not_in_scope_damages: Array.from(
          new Set(Object.keys(damageInfo).filter(x => damageInfo[x].notInScope).map(e => e))
        )
      }
     
      const { data:res } = await tasksApi.post(`tasks/${GETMYDATA?.id}/imageorder`, data);
      
      if (res?.message === "Successfully updated image order") {
        mixpanel.qa.track(events.ROOM_IMAGE_REORDERING_COMPLETE, {
          roomId:GETMYDATA?.roomId,
          claimId: GETMYDATA?.claimId,
          ...data,
        });
        logGlobalCompletionTime({
          startTime,
          roomId:GETMYDATA?.roomId,
          claimId: GETMYDATA?.claimId,
          closeEvent: events.ROOM_IMAGE_REORDERING_COMPLETE,
        });
        await statusCall("approved");
        clearCachedItems();
        dispatch(roomIssue([]));
        dispatch(RoomImageIssue([]));
        dispatch(selectedTaskData([]));
        dispatch(getTask([]));
        clearCachedItems();
      } else {
        alert("something went Wrong");
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const imageOrderChange = (images) => {
    setChangedImageOrder(images);
  };

  const handleChange = (newDamageInfo) => {
    setDamageInfo(newDamageInfo)
  };
  
  const disabledCompleteBtn = changedImageOrder.length < 4 || changedImageOrder.length > 16;

  return (
    <>
      <Overlay
        placement="top"
        target={completeBtnRef.current}
        show={showCompleteError}
        delay={{show: 250, hide: 400}}
      >
        {(props) => (
          <Tooltip id="complete-btn-error" {...props}>
            { changedImageOrder.length > 16 && select_16_best_imgs.enabled
              ? 'Only 16 images can be submitted, please select the best 16'
              : 'At least 4 Images are required to complete'
            }
          </Tooltip>
        )}
      </Overlay>
      <Row className="position-sticky bg-white sticky-top">
        <Col xs={6}>
          <h2 className="fs-3 mt-4 mb-4">Room Image Reordering</h2>
        </Col>
      </Row>
      <Row>
        { changedImageOrder.length > 16 && select_16_best_imgs.enabled ? (
          <Alert severity="info">
            When more than 16 images are present, the best 16 need to be chosen. Please archive duplicates and those that don't meet the standard, then do the appropriate reordering.
          </Alert>
        ) : null}
        <WallView imageChange={imageOrderChange} setArchiveImgs={(archive) => setArchivedOverviewImgs(archive)} />
      </Row>
      {selectedTaskValues?.damageImages_links?.length ? (
        <Row>
          <DamageImageOrdering onChage={handleChange} archivedOverviewImgs={archivedOverviewImgs} />
        </Row>
      ): null}
      <Row>
        <Col xs={12} md={12} lg={12} className="mt-lg-5">
          <Stack direction="row" spacing={2} justifyContent="end">
            <Button
              onClick={() => history.goBack()}
              variant="contained"
              size="large"
              disableElevation
              style={{
                backgroundColor: "#d2edb9",
                color: '#000'
              }}
              startIcon={<WestOutlinedIcon/>}
            >
              Prev
            </Button>
            <div
              onMouseOver={() => {
                if (!showCompleteError && disabledCompleteBtn) {
                  setShowCompleteError(true);
                }
              }}
              onMouseOut={() => {
                if (showCompleteError) {
                  setShowCompleteError(false);
                }
              }}
            >
              <Button
                ref={completeBtnRef}
                variant="contained"
                size="large"
                disableElevation
                disabled={disabledCompleteBtn}
                style={{...(!disabledCompleteBtn && {
                  backgroundColor: "#b7d8f1",
                  color: '#000'
                })}}
                startIcon={<DoneIcon/>}
                onClick={ImageOrderApiCall}
              >
                Complete
              </Button>
            </div>
          </Stack>
        </Col>
      </Row>
    </>
  );
};

export default RoomImageReordering;
