import React, { useEffect, useState } from "react";
import RoomImg from "../../data/RoomsImage.json";
import { Row, Col, Modal } from "react-bootstrap";
import "./RoomImage.scss";
import "./zoommodal.scss";
import ImageComplaint from "../ImageComplaint";
import ButtonComponent from "../ButtonComponent";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import {getTask, RoomImageIssue, roomIssue, selectedTaskData} from "../../store/actions/tasks";
import mixpanel from "mixpanel-browser";
import * as events from '../../utils/logger/events';
import portalApi from "../../utils/portalApi";
import * as Sentry from '@sentry/react';
import tasksApi from "../../utils/tasksApi";
import {logGlobalCompletionTime, clearCachedItems} from "../../utils/utils";
import {useReadLocalStorage} from 'usehooks-ts';
import roomPreviewData from "../../data/RoomsPreview.json";
import {useFlags} from "flagsmith/react";
import toast, { Toaster } from 'react-hot-toast';

const RoomImage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedTaskValues = useSelector(
    (state) => state?.taskReducer?.SelectedTaskData
  );
  const state = useSelector((state) => state?.taskReducer?.RoomImageIssue);
  const GETMYDATA = useSelector(
    (state) => state?.taskReducer?.myData?.tasks
  )?.[0] || [];
  
  const TASKDATA = useSelector((state) => state?.taskReducer?.taskData);
  const [images, setimages] = useState([]);
  const [information, setinformation] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [selectedPhotosIndex, setselectedPhotosIndex] = useState(0);
  const [zoomImage, setzoomImage] = useState(false);
  const [choocedData, setChoocedData] = useState([]);
  const startTime = useReadLocalStorage('start-time');
  const { select_16_best_imgs } = useFlags(['select_16_best_imgs']);
  
  const roomIssueData = useSelector(
    (state) => state.taskReducer.roomIssueState
  );
  
  useEffect(() => {
    const tasks = (TASKDATA?.tasks?.length || 0);
    if (tasks > 1 || tasks === 0) {
      alert("Please claim a task to proceed.");
      history.push("/app/qadashboard");
    }
  }, [TASKDATA.tasks, history]);
  
  useEffect(() => {
    if (GETMYDATA?.roomId && GETMYDATA?.claimId) {
      mixpanel.qa.track(events.ROOM_IMAGES_PAGE_LOAD, {
        roomId: GETMYDATA?.roomId,
        claimId: GETMYDATA?.claimId
      });
      
      (async () => {
        try {
          const { data } = await portalApi.get(`claim/${GETMYDATA?.claimId}/room/${GETMYDATA?.roomId}`);
          dispatch(selectedTaskData(data));
        } catch (e) {
          Sentry.captureException(e);
        }
      })();
    }
    // eslint-disable-next-line
  }, [GETMYDATA]);

  useEffect(() => {
    setinformation(RoomImg.roomImages.informations);
    setPageTitle(RoomImg.roomImages.pageTitle);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let additionalImages_links =
      selectedTaskValues?.additionalImages_links || [];
    let images_links = selectedTaskValues?.images_links || [];
    
    let imageIds = [];
    selectedTaskValues?.imagesToProcess?.forEach((data) => {
      imageIds.push(data.substr(0, data.lastIndexOf(".")));
    });
    
    let combineImages = images_links.concat(additionalImages_links);
    
    let finalImages = [];
    imageIds?.forEach((imageId) => {
      combineImages?.forEach((image) => {
        if (image?.includes(imageId)) {
          finalImages?.push(image);
        }
      });
    });

    if (!select_16_best_imgs.enabled) {
      finalImages = Array.from(new Set(finalImages));
      imageIds = Array.from(new Set(imageIds));
      setimages(finalImages);
    } else if (selectedTaskValues?.overviewImages_links) {
      setimages(selectedTaskValues.overviewImages_links);
    }
    // eslint-disable-next-line
  }, [selectedTaskValues]);

  useEffect(() => {
    const dummy = images?.map((imagedata, index) => {
      return {
        id: index,
        imageVal: imagedata,
        QaErrors: [],
        FoundErrors: [],
      };
    });
    setChoocedData(state?.length > 0 ? state : dummy);
  }, [images, state]);

  const onNextPhotos = () => {
    setselectedPhotosIndex((selectedPhotosIndex + 1) % images?.length);
  };
  const onPrevPhotos = () => {
    setselectedPhotosIndex(Math.abs(selectedPhotosIndex - 1) % images?.length);
  };

  const imgComplaint = (data, id) => {
    mixpanel.qa.track(events.ROOM_IMAGES_IMAGE_COMPLAINTS, {
      roomId:GETMYDATA?.roomId,
      claimId: GETMYDATA?.claimId,
      imageId: id,
      complaints: data,
    });
    setChoocedData((prevState) => prevState?.map((d) => {
      return {
        ...d,
        QaErrors: d.id === id ? data : d.QaErrors,
      }
    }));
  };

  const statusCall = async (status) => {
    const {data} = await tasksApi.post(`tasks/${GETMYDATA?.id}?status=${status}`, {});
    if (data.status) {
      history.push("/app/qadashboard");
    } else {
      alert("facing Some Issue / Please try again later");
    }
  };

  const postError = async () => {
    try {
      let allErrors = [];
      choocedData?.forEach((data) => {
        //ERRORS JSON MAKING
        let QaErrorsData = [];
        let FoundErrorsData = [];
        // Match the UUID from the image URL and extract it
        const imageId = data?.imageVal?.match?.(/[0-9a-fA-F-]{36}/)?.[0] || '';
        
        data?.QaErrors?.forEach((qa) => {
          QaErrorsData.push(qa);
        });
        
        data?.FoundErrors?.forEach((qa) => {
          FoundErrorsData.push(qa);
        });
        
        if (QaErrorsData?.length > 0) {
          allErrors.push({
            error: QaErrorsData,
            imageid: imageId,
            severity: "red",
          });
        }
        
        if (FoundErrorsData?.length > 0) {
          allErrors.push({
            error: FoundErrorsData,
            imageid: imageId,
            severity: "yellow",
          });
        }
      });
      

      if (!allErrors.length) {
        toast.error('Please select at least one error for each of the images.');
        return;
      }

      // Prevent to send duplicate imageId's
      const uniqueErrorsMap = new Map();
      
      allErrors.forEach(error => {
        uniqueErrorsMap.set(error.imageid, error);
      });
      
      const uniqueErrors = Array.from(uniqueErrorsMap.values());
      
      const errors_image_qa = {
        image_errors: uniqueErrors,
      };

      const { data } = await tasksApi.post(`tasks/${GETMYDATA?.id}/errors`, { errors_image_qa });
      if (data.message === "Success") {
        await statusCall("failed");
        logGlobalCompletionTime({
          startTime,
          roomId:GETMYDATA?.roomId,
          claimId: GETMYDATA?.claimId,
          closeEvent: events.ROOM_IMAGES_REJECTED,
        });
        mixpanel.qa.track(events.ROOM_IMAGES_REJECTED, {
          roomId:GETMYDATA?.roomId,
          claimId: GETMYDATA?.claimId,
          errors: allErrors,
        });
        mixpanel.capture.track(events.ROOM_IMAGES_REJECTED, {
          roomId:GETMYDATA?.roomId,
          claimId: GETMYDATA?.claimId,
          errors: allErrors,
        });
        dispatch(selectedTaskData([]));
        dispatch(getTask([]));
      } else {
        toast.error('facing Some Issue / Please try again later.');
      }
    } catch (e) {
      Sentry.captureException(e);
      toast.error('Unexpected error occurred. Please try again later.');
    } finally {
      dispatch(roomIssue([]));
      dispatch(RoomImageIssue([]));
      clearCachedItems();
    }
  };
  
  const missingWalls = roomIssueData?.filter((issue) => issue === roomPreviewData?.roomsPreview?.issues?.[0]?.message);
  const allErrors = choocedData.map((r) => r.QaErrors).flat(1);
  
  return (
    <React.Fragment>
      <Toaster position="top-right" />
      <div className="room-img-content">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className="section-title mt-5 mb-5">
              <h2>{pageTitle}</h2>
            </div>
            <div className="img-card-content row">
              {choocedData.length ? images?.map((data, index) => {
                return (
                  <div className="col-md-3 border bg-light mb-4">
                    <div className="row">
                      <div className="col-12">
                        <img
                          src={data}
                          alt="room"
                          onClick={() => {
                            setselectedPhotosIndex(index);
                            setzoomImage(true);
                          }}
                          style={{ width: '100%', height: 'auto' }}
                        />
                      </div>
                      <div className="col-12">
                        <ImageComplaint
                          id={`image-complaint-${index}`}
                          data={information.imgComplaint}
                          func={(data) => imgComplaint(data, index)}
                        />
                      </div>
                    </div>
                  </div>                  
                );
              }) : null}
            </div>
          </Col>
        </Row>
        <div className="decision-section">
          <Row>
            <Col xs={12} md={12} lg={12}>
              <ButtonComponent
                disabledNext={missingWalls.length || allErrors.length}
                disabledReject={!allErrors.length && !missingWalls.length}
                next={() => {
                  mixpanel.qa.track(events.ROOM_IMAGES_NEXT, {
                    roomId:GETMYDATA?.roomId,
                    claimId: GETMYDATA?.claimId
                  });
                  history.push("/app/maincomponent/roomImageReordering");
                }}
                reject={postError}
              />
            </Col>
          </Row>
        </div>
        <Modal
          onHide={() => {
            setzoomImage(false);
          }}
          contentClassName="preview-img-modal modelzoomeffect"
          show={zoomImage}
          centered
          animation
          size="xl"
          className="shadow-lg"
        >
          <div onClick={onPrevPhotos} className="arrow arrow-left">
            <FiChevronLeft className="arrow-icon" color="#ffffff"/>
          </div>
          {images?.[selectedPhotosIndex] && <img alt="carousel" src={images[selectedPhotosIndex]} width="100%"/>}
          <div onClick={onNextPhotos} className="arrow arrow-right">
            <FiChevronRight className="arrow-icon" color="#ffffff"/>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default RoomImage;
